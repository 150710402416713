// render load time

window.addEventListener('load', () => {
  const loadTime = (window.performance.timing.loadEventStart - window.performance.timing.navigationStart) / 1000
  const thunderbolt = loadTime < 1 ? '⚡' : ''

  if (loadTime > 0 && loadTime < 5) {
    document.querySelector('.load-time').textContent = `This page loaded in ${loadTime.toFixed(2)} seconds ${thunderbolt}`
  }
})

// render footer year

document.querySelector('.year').textContent = (new Date()).getFullYear()

// render email

document.querySelector('a[title="Email"]').setAttribute('href', 'mailto:bromy.co@gmail.com?body=Hey Ben!')

// say hello

console.log('<br>')